   
   <template> 
   <div>

   <beautiful-chat
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="false"
      :showFile="false"
      :showEdition="false"
      :showDeletion="false"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="true"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :disableUserListToggle="true"
      :messageStyling="messageStyling"
      @onType="handleOnType"
       >

<template> [{{$t('chat.system')}}]: 123 </template>

<template v-slot:header>
    <template v-if="!socketAuth">{{$t('chat.offline')}} </template>
    <template v-else> 
        <div :class="'chatOnline ' + (supportOnline > 0 ? 'onSupport': 'offSupport')" :data-original-title="supportOnline > 0 ? $t('chat.supportOnline'): $t('chat.supportOffline')" data-toggle="tooltip" title=""></div>		
        <template v-if="participants.length > 1">
            <div v-html="participantsHeader"></div>
        </template> 
        <template v-else>
       {{$t('chat.msg.5')}} 
        </template>
     </template> 
</template> 

<template v-slot:user-avatar="{ message, user }" >
    <div v-html="avaRender(message)" style="display: flex;"/>
</template>

  <template v-slot:text-message-body="scopedProps" v-if="socketAuth">
  <div v-html="renderMess(scopedProps)"/>

  </template>

 </beautiful-chat>
</div>
</template>
<script>
  import Vue from 'vue';  
  import Chat from 'vue-beautiful-chat'
  import configStresser from '../../helpers/config';
  Vue.use(Chat);
  import socket from '../../helpers/socket'
  import _ from 'lodash';
  Vue.set(Vue.prototype, '_', _);
  import axios from "axios";
  import {mapActions, mapGetters, mapState} from 'vuex'
  export default {
    name: 'Chat',
    props: {},
    data() {
      return {
        title: 'Main wrapper',    
        userInfo: { login: ''},
        currentTime: Math.floor(Date.now() / 1000),
        ban: 0,
        initChat: false,
        archive: 0,
        supportOnline: 0,
        loadChat: false,
        banned: '',
          participants: [
             {
              id: '1',
              name: 'Support',
              imageUrl: '/uploads/ava/support.svg'
            },
            ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
            titleImageUrl: '/uploads/ava/admin2.svg',
            messageList: [
               {
                  author: '1',
                  type: 'text',
                  id: 1, // or text '1'
                  data: {
                    text: this.$t('chat.msg.4'),
                  },
                  suggestions: [this.$t('chat.msg.1'), this.$t('chat.msg.2'), this.$t('chat.msg.3')]
                }
               
            ], // the list of the messages to show, can be paginated and adjusted dynamically
            newMessagesCount: 0,  
            timeOutTyping: '',
            isChatOpen: false, // to determine whether the chat window should be open or closed
            showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
            colors: {
              header: {
                bg: '#4e8cff',
                text: '#ffffff'
              },
              launcher: {
                bg: '#4e8cff'
              },
              messageList: {
                bg: '#ffffff'
              },
              sentMessage: {
                bg: '#705ec8',
                text: '#ffffff'
              },
              receivedMessage: {
                bg: '#20223c',
                text: '#ffffff'
              },
              userInput: {
                bg: '#f4f7f9',
                text: '#565867'
              }
            }, // specifies the color scheme for the component
            alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
            messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
          }
    }, 
    beforeMount() {
        //let tokenCSRF = document.head.querySelector("[name~=csrf-token][content]").content;
        //this.updateToken(tokenCSRF);  
    },
    mounted() {
      const ps1 = new PerfectScrollbar('.sc-message-list', {
        useBothWheelAxes:true,
        suppressScrollX:true,
      });
      
      this.socketInit(false);
      
    },
   
    computed: {
    
     participantsHeader: function() {         

        let particUsers = this.participants.filter(p => this.user.user_id != p.id);

        return particUsers.map((m)=>{ 

            if (this.user.user_id != m.id) {
                return '<span style="color: '+ this.colorsName(m.group) + '">' + m.name + '</span>'  
            }
          }).join(', ')
      },
      user: function() {         
          return JSON.parse(this.$store.state.user);
      },
      ...mapState([
        'logged',
        'loader',
        'token',
        'socketAuth',
        'csrf'
      ]),

    },
    watch: {
      socketAuth(value) {
          if (value == false) {
              this.isChatOpen = false
          }
      },
      newMessagesCount(value) {
          if (value > 0 && this.isChatOpen == true) {
            this.$socket.emit('read', JSON.stringify({
                for_user_id: 1,
                act: 0,
            }));
            this.newMessagesCount = 0;
          } 
      },
      ban(value) {
          if (this.ban == 1) {
             this.messageList = [ ...this.messageList,  { type: 'system', id: Math.floor(Date.now() / 1000), data: { text: this.banned, meta: '' } }];
          }
      },
      archive(value) {
          if(this.archive == 1 && this.ban == 0) {
            this.messageList = [ ...this.messageList,  { type: 'system', id: Math.floor(Date.now() / 1000), data: { text: this.$t('chat.over'), meta: '' } }];
          } else {
            let systemID = this.messageList.findIndex(x => x.type === 'system');
            this.messageList.slice(systemID, 1);
          }
      } 
    },
    sockets: {     
        message: function (data) {
           this.Pucher(data);
        },
        connect: function (data) {
          this.$store.dispatch('SET_AUTH_SOCKET', true);
          this.chatUpdate();
        },
        disconnect: function (data) {
          this.$store.dispatch('SET_AUTH_SOCKET', false);
         // this.$socket.disconnect();
        },
    },
    beforeMount() {
      this.$socket.connect();
    },
    methods: {
      ...mapActions([
        'updateToken',
      ]),
      avaRender(mess) {

        let index = this.messageList.findIndex(x => x.msg_id === mess.msg_id);
      if (index > 0) {
        if ( this.messageList[index -1].author == mess.author) {
            return '<div class="sc-message--avatar has-tooltip"></div>';
        } else if(mess.hasOwnProperty('imageUrl')) {
           return '<div class="sc-message--avatar has-tooltip" style="background-image: url(' + mess.imageUrl +');"></div>';
       }
      }  else if(mess.hasOwnProperty('imageUrl')) {
           return '<div class="sc-message--avatar has-tooltip" style="background-image: url(' + mess.imageUrl +');"></div>';
       } else if (this.participants[index].hasOwnProperty('imageUrl')) {
          return '<div class="sc-message--avatar has-tooltip" style="background-image: url(' + this.participants[index].imageUrl +');"></div>';
       } 

      },
      socketInit(notify) {
        let self = this;
        if(!self.socketAuth) {
         
            self.$socket.connect();
             
              if (self.logged) {
                self.$socket.connect();
                self.$socket.emit('autch', JSON.stringify({
                    user_id: self.user.user_id,
                    password: self.user.hid
                }));
              }
              if (notify) {
                setTimeout(function(){ 
                    if (!self.socketAuth) {
                      self.$toast(self.$t('chat.lost'), {
                          type: 'error',
                          position: configStresser.notifyPosition,
                          timeout: 2000
                      });
                } 
                }, 500);
              }
        }

      },
      renderMess(scopedProps) {
        let id;

        if (scopedProps.message.author == 'me') {
            id = this.user.user_id;
        } else {
            id = scopedProps.message.author;
        }


        let index = this.messageList.findIndex(x => x.msg_id === scopedProps.message.msg_id);
        let authorIndex = this.participants.findIndex(x => x.id === id);
        let classText = "sc-message--text-content";
        
        if (scopedProps.message.data.text.length >= 29) {
            classText = classText + ' inc-time';
        }

       
        let adate = '<span class="sc-message--date">'+scopedProps.message.date+'</span>';
        if (!scopedProps.message.hasOwnProperty('date')) {
            adate = '';
        }


        let messHtml = '<p class="'+classText+'">'+scopedProps.message.data.text+ adate +'</p>';
      
        let name = '';
        
         if (this.user.user_id != id && authorIndex != -1) {
            name = '<div class="sc-title-author group-'+scopedProps.message.role + '">' + this.participants[authorIndex].name + '</div>';
        }

      if (index > 0) {

        let countMess = this.messageList.length - 1;
        let nextMessIndex = index + 1;

        let prevMess = this.messageList[index -1];
        let mess = this.messageList[index];

        if ( this.messageList[index -1].author == id) {
           name = '';
        } 

        if (nextMessIndex > countMess) {
            return name + '<div class="wrapMess">' + messHtml+ '</div>';
        } else {

          if (this.messageList[nextMessIndex].author == scopedProps.message.author) {
            return name + '<div class="wrapMess noDate">' +messHtml+ '</div>';
          } else {
              return name +  '<div class="wrapMess">' +messHtml+ '</div>';
          }
        }
      } else {
          if (this.user.user_id != id) {
             return name + '<div class="wrapMess">' +messHtml+ '</div>';
          } else {
             return name + '<div class="wrapMess">' +messHtml+'</div>';
          }
       }

      },

      Pucher(for_user_id, user_id, data, room, type){
				let d = JSON.parse(for_user_id);
        
				if(d.user_id == 'msg'){
	        if (d.room.data.hasOwnProperty('text')) {
              this.newMessagesCount = this.newMessagesCount + 1;
              this.messageList = [ ...this.messageList, d.room];
              this.showTypingIndicator = '';
              let checkParticipants = false;
              this.participants.map(x => {if (x.id == d.room.author) checkParticipants = true} );
              if (checkParticipants == false) {
                  this.chatUpdate();  
              }
          }   
				} else if(d.user_id == 'archive'){
					this.archive = 1;
				} else if(d.user_id == 'ban'){
          this.ban = 1;
          this.banned = this.$t('chat.blocked');
				}
				else if(d.user_id == 'typing'){

            //let participantsTyping = this.participants.findIndex(x => x.id === d.message);
            clearTimeout(this.timeOutTyping);
            this.showTypingIndicator = d.message;
            
						this.timeOutTyping = setTimeout(() => {
								 this.showTypingIndicator = '';
						}, 1000);
       
	
				}
	
		},	
    
        SendPush(for_user_id, user_id, data, room, type){
          this.$socket.emit('messageSupport', JSON.stringify({
              for_user_id: for_user_id,
              user_id: user_id,
              room: room,
              message: data,
              type: type
          }));
        },
        sendMessage (text) {
          if (text.length > 0) {
            this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
            this.messageList = [ ...this.messageList, { author: this.user.user_id, type: 'text', data: { text } }];
          }
        },
        onMessageWasSent (message) {
         
          if (this.archive == 1) { this.archive = 0 }

          if (this.ban == 0) {

            axios({
              method: 'post',
              url: '/chat',
              data: {
                act: 'send',
                switch: 'send',
                token: this.csrf.token,
                msg: message.data.text
              },
              headers: {
                "Content-type": "application/json"
              }
            }).then((response) => {
                message.date = response.data.date;
                message.msg_id = response.data.msg_id;    

                // called when the user sends a message
                this.messageList = [ ...this.messageList, message ];

                this.SendPush(1, 'msg', this.user.user_id);
                this.newMessage = null;
            });

          } else {
            this.$toast(this.$t('chat.blocked'), {
              type: 'error',
              position: configStresser.notifyPosition,
              timeout: 2000
            });
          }
        },
        colorsName(group) {         
            if (group == 4) {
                return '#5b7fff';
            }  else if (group == 1) {
              return '#ef4b4b';
            } else {
              return '#fff';
            }
        },
        readMess() {

          axios({
            method: 'post',
            url: '/chat',
            data: {
              act: 'read',
              token: this.csrf.token,
            },
            headers: {
              "Content-type": "application/json"
            }
            })
            .then((response) => {
              let result = response.data;
              
              if (result.status == 'success') {
                  this.newMessagesCount = 0;
              }
            });
        }, 
        loaderChat() {
             if (this.socketAuth) {
                if(this.newMessagesCount > 0) {
                  this.$socket.emit('read', JSON.stringify({
                      for_user_id: 1,
                      act: 0,
                  }));
                  this.newMessagesCount = 0;
                }

                if (this.initChat === false) {
                    this.chatUpdate();
                }
                this.isChatOpen = true
            }
        },
        openChat() {
            let self = this;
            if (!this.socketAuth) {
              this.socketInit(true);
            }
            this.loaderChat();
        },
        chatUpdate() {
           axios({
                  method: 'post',
                  url: '/chat',
                  data: {
                    act: 'history',
                    token: this.csrf.token
                  },
                  headers: {
                    "Content-type": "application/json"
                  }
                  })
                  .then((response) => {
                    if (response.data.status == 'success') {
                      this.messageList = response.data.messList;
                      this.participants = response.data.participants;

                      this.archive = response.data.archive;
                      this.ban = response.data.ban;
                      this.banned  = response.data.banned;
                      this.newMessagesCount = Number(response.data.pm_num);
                      this.readActive = false;		
                      this.initChat = true;
                      this.members = response.data.members;
                      this.archive = response.data.archive;
                      this.msg_num = response.data.pm_num;
                      this.ban = response.data.ban;
                      this.banned  = response.data.banned;
                      // setTimeout(() => {
                      //   this.scrollToEnd();
                      // }, 250);
                    
                    } else {

                      if (response.data.msg == 'no_msg') {
                          this.initChat = true;
                      } else {
                          this.ban = response.data.ban;
                          this.banned  = response.data.banned;
                      }

                
                    }
                    this.supportOnline = response.data.online;
             });
        },
        closeChat () {
          this.isChatOpen = false
        },
        handleScrollToTop () {
        },
        handleOnType () {
           this.SendPush(1, 'typing', this.user.user_id); 
        },
        handleTyping(text) {
          this.showTypingIndicator =
            text.length > 0 ? this.participants[this.participants.length - 1].id : ''
        },
        editMessage(message){
          const m = this.messageList.find(m=>m.id === message.id);
          m.isEdited = true;
          m.data.text = message.data.text;
        },
        debug (event) {
          console.log(event)
        }
    },
    components: {
      
    },
  }
</script>
