export default  {
  IS_MOBILE(state) {
    return state.isMobile;
  },
  IS_DESKTOP(state) {
    return state.isDesktop;
  },
  user(state) {
    return state.user;
  },
  logged: state => !!state.user,
  authStatus: state => state.status
}
