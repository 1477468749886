<template> 
		<div class="dropdown header-notify" >
			<a class="nav-link icon" data-toggle="dropdown" @click="pm > 0 ? loadNotify(1, 0): ''">

<svg xmlns="http://www.w3.org/2000/svg" style="box-shadow: none;background: none;border: none;" class="header-icon notifyx" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707C3.105 15.48 3 15.734 3 16v2c0 .553.447 1 1 1h16c.553 0 1-.447 1-1v-2c0-.266-.105-.52-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707C6.895 14.52 7 14.266 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zM12 22c1.311 0 2.407-.834 2.818-2H9.182C9.593 21.166 10.689 22 12 22z"></path></svg>

			<span class="pulse notPulse" v-if="pm > 0"></span></a>
			<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow animated" style="padding-bottom: 0px;width: 100%;">
	
				 <div class="text-center">
	
				<p class=" text-center user pb-0 font-weight-bold mb-0">{{$t('notifications.notifications')}}</p>
				<div class="dropdown-divider"></div>
				</div>

				  <perfect-scrollbar class="notify-menu psNotify" :options="settings" id="notify" v-if="Object.keys(notify).length > 0">

					<a class="dropdown-item border-bottom d-flex pl-4" href="#" v-for="n in notify" style="padding-right: 0;">
					<div :class="'notifyimg bg-' + n.color +'-transparent text-'+n.color" style="margin-right: 15px;display: flex;align-items: center;justify-content: center;">
						<i :class="n.icon"></i>
					</div>
					<div class="flexNotify">
						<p>
							{{n.text}} 
						</p>
						<div class="small text-muted">
							{{n.date}} 
						</div>
					</div></a> 

				</perfect-scrollbar>
				<div class="notifyText" v-else>{{$t('notifications.no')}}</div>
				
			</div>
		</div>
</template>
<script>
  import Vue from 'vue';  
  import Chat from 'vue-beautiful-chat'
  Vue.use(Chat);

  import store from 'vuex';
  import socket from '../../helpers/socket'

  import _ from 'lodash';
  import axios from "axios";
  import {mapActions, mapGetters, mapState} from 'vuex'
  export default {
    name: 'Notify',
    props: {},
    data() {
      return {
       	  notify: [],
			open: false,
			pm: 0,
			supportMsg: 0,
			settings: {
					useBothWheelAxes:true,
					suppressScrollX:true,
			}
      }
    }, 
    beforeMount() {
        //let tokenCSRF = document.head.querySelector("[name~=csrf-token][content]").content;
        //this.updateToken(tokenCSRF);  
	
    },
    mounted() {
		if (this.logged) {
       		this.loadNotify(0, 1);
		}
    },
    
    computed: {
      user: function() {         
          return JSON.parse(this.$store.state.user);
      },
      ...mapState([
        'logged',
        'loader',
        'token',
		'socketAuth',
        'csrf'
      ]),

    },
    watch: {
    },
    sockets: {     
        notify: function (data) {
			this.loadNotify(0, 1);
        },
		loadNotify: function (data) {

         // this.$socket.disconnect();
        },
    },
    methods: {
		...mapActions([
			'updateToken',
		]),
		loadNotify(read, act) {
			if (this.pm > 0 || act === 1) {

			axios({
				method: 'post',
				url: '/chat',
				data: {
					act: 'notify',
					read: read,
					token: this.csrf.token,
				},
				headers: {
					"Content-type": "application/json"
				}
				})
				.then((response) => {
					if (response) {
						let result = response.data;
						if (result.status == 'success') {
							this.notify = result.notify;
							this.pm = result.pm_read;
							if (read == 1) {
								this.pm = 0;
							}
							this.open = true;
						}
					}
	
				});
			}
		}, 

		debug (event) {
			console.log(event)
		}
    },
    components: {
      
    },
  }
</script>
