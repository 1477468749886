import Vue from 'vue'
import Vuex from 'vuex'

import commonActions from './actions/actions'
import apiRequests from './actions/api-requests'
import mutations from "./mutations/mutations"
import getters from "./getters/getters";
import configStresser from '../helpers/config'

const actions = {...commonActions, ...apiRequests}

Vue.use(Vuex);

let userIn = localStorage.getItem('user');
let defaultUser = {login: 'user', user_id: 0};
let lang = localStorage.getItem('lang');

let store = new Vuex.Store({
  state: {
    isMobile: false,
    isDesktop: true,
    loader: true,
    mrl: '',
    mrlStatus: false,
    currency: configStresser.currency,
    home: 'Stresse.APP',
    socketAuth: false,
    auth: { token: null, tokenExpire: null, refreshToken: null},
    csrf: {
      token: null,
      tokenExpire: null,
      refreshToken: null,
    },
    logged: userIn ? true : false,
    user: userIn ? userIn : JSON.stringify(defaultUser),
    twoAuth: false,
    langConfig: {
      list: [
        {name: 'English', short: 'en', icon: 'flag flag-gb'},
        //{name: 'Russian', short: 'ru', icon: 'flag flag-ru'},
        {name: 'Chinese', short: 'cn', icon: 'flag flag-cn'},
        //{name: 'France', short: 'fr', icon: 'flag flag-fr'},
        {name: 'Germany', short: 'de', icon: 'flag flag-de'},
        {name: 'Czech', short: 'cz', icon: 'flag flag-cz'}
      ],
      active: lang ? lang : 0,
    }
  },
  mutations,
  actions,
  getters,
});

export default store;
