<template>
  <div id="app">
    <transition name="fade" v-if="loader">
      <div id="global-loader">
        <img src="./assets/images/loader.svg" alt="loader" />
      </div>
    </transition>

    <!--header-->
    <div class="hor-header header top-header">
      <div class="container">
        <div class="d-flex logoblock" style="align-items: center;">
          <a class="animated-arrow hor-toggle horizontal-navtoggle" @click="toggleMenu">
            <span></span>
          </a>
          <router-link :to="logged ? '/home' : '/login'">
            <div class="header-brand" style="display: flex; align-items: center;">
              <img
                src="../src/assets/images/logo.svg"
                class="header-brand-img desktop-lgo"
              />
              <img
                src="../src/assets/images/logo.svg"
                class="header-brand-img dark-logo"
              />
              <h2 class="mb-1 font-weight-bold logotext" style="margin-left: 17px; margin-top: 5px;">
                {{ home }}
              </h2>
            </div>
          </router-link>
          <div class="notification" v-if="user.group == 1">
            <div class="notification__content">
              <p class="notification__text">Stay up to date with all the news on our Telegram channel</p>
              <a class="notification__button" target="_blank" href="https://t.me/stresse">GO</a>
            </div>
          </div>
          <div class="d-flex order-lg-2 ml-auto" id="notification" style="align-items: center;">
            <div class="mirror" v-if="mrl && logged">
              <div class="mirror__input">
                <div class="mirror__block">
                  <i class="fa fa-code"></i>
                  <span class="mirror__text">Mirror</span>
                </div>
                <input
                  type="text"
                  :class="mrlStatus ? 'greenStatus' : ''"
                  :value="mrlStatus ? 'You are on a mirror!' : mrl"
                />
                <a :href="mrl" class="mirror__button" target="_blank" v-if="!mrlStatus">GO</a>
                <a :href="'https://' + defaultDomain" class="mirror__button" v-if="mrlStatus">
                  Back to main domain!
                </a>
              </div>
            </div>

            <Notify v-if="logged" />


            <div class="dropdown profile-dropdown align-items-center" v-if="logged && user">
            <a href="#" class="sub-icon notifyx" @click.prevent="toggleDropdown" ref="dropdownTrigger" >
              <i class="fa fa-user hor-icon" style="font-size: 16px;"></i>
              {{ user.login }}
            </a>

            <transition name="fade-slide">
              <div
                v-if="isDropdownOpen"
                class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" ref="dropdownMenu"
              >
                <div class="text-center">
                  <p class="text-center user pb-0 font-weight-bold mb-0">{{ user.login }}</p>
                  <div class="dropdown-divider"></div>
                </div>

                <router-link to="/profile" class="dropdown-item d-flex"  @click.native.prevent="isDropdownOpen = false">
                  <svg class="header-icon mr-3" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"
                    />
                  </svg>
                  <div class="">{{ $t('main.profile') }}</div>
                </router-link>

                <a class="dropdown-item d-flex" href="#" @click="logout">
                  <svg class="header-icon mr-3" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                    <g>
                      <rect fill="none" height="24" width="24" />
                    </g>
                    <g>
                      <path
                        d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"
                      />
                    </g>
                  </svg>
                  <div class="">{{ $t('main.exit') }}</div>
                </a>
              </div>
            </transition>
          </div>
                
      <nav class="horizontalMenu clearfix"  v-if="!logged">
          <div class="horizontal-overlapbg" @click="closeMenu"></div>
          <ul class="horizontalMenu-list" style="margin-bottom: 0;">
            <li aria-haspopup="true" class="align-items-center sub-icon">
              <router-link to="/login">
                <i class="fa fa-user hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.login') }}
              </router-link>
            </li>
            <li aria-haspopup="true" class="align-items-center sub-icon" v-if="!mrl">
              <router-link to="/register">
                <i class="fa fa-user-plus hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.register') }}
              </router-link>
            </li>
          </ul>
        </nav>
          </div>
    
        </div>
        

      </div>




    </div>


   
    <!-- Main Horizontal Menu -->
    <div class="horizontal-main hor-menu clearfix" v-if="logged">
      <div class="horizontal-mainwrapper container clearfix" style="justify-content: center; display: flex;">
        <nav class="horizontalMenu clearfix">
          <div class="horizontal-overlapbg" @click="closeMenu"></div>
          <ul class="horizontalMenu-list">
            <li aria-haspopup="true" class="sub-icon">
              <router-link to="/home" active-class="active">
                <i class="fa fa-home hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.home') }}
              </router-link>
            </li>
            <li aria-haspopup="true" class="sub-icon">
              <router-link to="/panel">
                <i class="fa fa-feed hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.panel') }}
              </router-link>
            </li>
            <li aria-haspopup="true" class="sub-icon">
              <router-link to="/purchase">
                <i class="fa fa-shopping-cart hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.purchase') }}
              </router-link>
            </li>
            <li aria-haspopup="true" class="sub-icon">
              <router-link to="/api">
                <i class="fa fa-code hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.api') }}
              </router-link>
            </li>
            <li aria-haspopup="true" class="sub-icon">
              <router-link to="/help">
                <i class="fa fa-life-ring hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.help') }}
              </router-link>
            </li>
            <li aria-haspopup="true" class="sub-icon">
              <router-link to="/faq">
                <i class="fa fa-question-circle hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.faq') }}
              </router-link>
            </li>
            <li aria-haspopup="true">
              <a class="sub-icon" href="https://t.me/msctf" target="_blank">
                <i class="fa fa-info hor-icon" style="font-size: 16px;"></i>
                {{ $t('main.contact') }}
              </a>
            </li>
            <li aria-haspopup="true">
              <a class="sub-icon" href="https://t.me/stresse" target="_blank">
                <i class="fa fa-telegram hor-icon" style="font-size: 16px;"></i>
                TELEGRAM
              </a>
            </li>
            <li
              aria-haspopup="true"
              style="font-weight: bold; color: #fff;"
              v-if="!mrlStatus && mrl && logged"
            >
              <a class="sub-icon" :href="mrl" target="_blank">
                <i class="fa fa-code hor-icon" style="font-size: 16px;"></i>
                Mirror GO
              </a>
            </li>
            <li
              aria-haspopup="true"
              style="font-weight: bold; color: #fff;"
              v-if="mrlStatus"
            >
              <a class="sub-icon" :href="'https://' + defaultDomain" target="_blank">
                <i class="fa fa-code hor-icon" style="font-size: 16px;"></i>
                Back to main domain!
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!--/header-->

    <!-- Page -->
    <div class="page">
      <router-view></router-view>
      <!-- Footer -->
      <footer class="footer">
        <div class="container">
          <div class="row align-items-center flex-row-reverse">
            <div class="col-md-12 col-sm-12 text-center" style="display: flex; justify-content: center;">
              <div class="infos">
                Copyright © 2023 <a href="#">{{ home }}</a>
              </div>

              <div class="locales">
                <a
                  href="#"
                  data-toggle="dropdown"
                  class="sub-icon langHov"
                  style="height: 43px; padding-left: 15px;"
                >
                  {{ langConfig.list[langConfig.active].name }}
                  <i :class="langConfig.list[langConfig.active].icon" style="margin-right: 10px;"></i>
                </a>

                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow animated dropLang">
                  <div class="text-center">
                    <p class="text-center user pb-0 font-weight-bold mb-0">
                      {{ $t('others.lang') }}
                    </p>
                    <div class="dropdown-divider"></div>
                  </div>

                  <a
                    class="dropdown-item d-flex"
                    href="#"
                    @click="changeLang(i)"
                    v-for="(lang, i) in langConfig.list"
                  >
                    <div class="">
                      <i :class="lang.icon" style="margin-right: 10px;"></i>{{ lang.name }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- End Footer -->
    </div>

    <Chat v-if="logged" />
    <!-- End Page -->
  </div>
</template>

<script>
import Chat from './views/chat/index.vue';
import Notify from './views/notify/index.vue';
import socket from './helpers/socket';
import { lang, translation } from './components/lang';
import _ from 'lodash';
import axios from 'axios';
import configStresser from './helpers/config';
import { mapActions, mapState } from 'vuex';
import { nextTick } from 'vue';

export default {
  name: 'App',
  data() {
    return {
      mrlStatus: false,
      defaultDomain: '',
      isDropdownOpen: false,
      currentTime: Math.floor(Date.now() / 1000),
    };
  },
    mounted() {
    // Проверка на статус зеркала
    let re = /^(?:https?:\/\/)?(?:www\.)?([^/]+)/;
    if (this.mrl && this.mrl.match(re)[1] === location.hostname) {
      this.mrlStatus = true;
    }
    this.defaultDomain = configStresser.defaultDomain;

    // Обработчик изменения размера окна
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('click', this.handleOutsideClick);
    // Вызов handleResize после того, как DOM обновится
    this.$nextTick(() => {
      this.handleResize(); // вызов для начальной настройки
    });
    
  },
  beforeDestroy() {
    // Удаление обработчика при уничтожении компонента
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(event) {
       if (this.isDropdownOpen && !this.$refs.dropdownMenu.contains(event.target) && !this.$refs.dropdownTrigger.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },  
    toggleMenu() {
      document.body.classList.toggle('active');
    },
    closeMenu() {
      document.body.classList.remove('active');
    },
    initHorizontalMenu() {
      const menuItems = document.querySelectorAll('.horizontalMenu > .horizontalMenu-list > li');

      menuItems.forEach((item) => {
        if (item.querySelector('.sub-menu') || item.querySelector('.horizontal-megamenu')) {
          const clickSpan = document.createElement('span');
          clickSpan.className = 'horizontalMenu-click';
          clickSpan.innerHTML = '<i class="horizontalMenu-arrow fa fa-angle-down"></i>';
          item.prepend(clickSpan);

          clickSpan.addEventListener('click', () => {
            clickSpan.classList.toggle('horizontal-activearrow');
            const subMenu = item.querySelector('.sub-menu');
            const megamenu = item.querySelector('.horizontal-megamenu');
            if (subMenu) subMenu.classList.toggle('active');
            if (megamenu) megamenu.classList.toggle('active');
          });
        }

        const subItems = item.querySelectorAll('ul > li');

        subItems.forEach((subItem) => {
          if (subItem.querySelector('.sub-menu')) {
            const clickSpan02 = document.createElement('span');
            clickSpan02.className = 'horizontalMenu-click02';
            clickSpan02.innerHTML = '<i class="horizontalMenu-arrow fa fa-angle-down"></i>';
            subItem.prepend(clickSpan02);

            clickSpan02.addEventListener('click', () => {
              clickSpan02.querySelector('.horizontalMenu-arrow').classList.toggle('horizontalMenu-rotate');
              subItem.querySelector('.sub-menu').classList.toggle('active');
            });
          }
        });
      });
    },
    handleResize() {
      const horizontalMenu = document.querySelector('.horizontalMenu');

      if (window.outerWidth < 992) {
        horizontalMenu.style.height = `${window.innerHeight}px`;
      } else {
        horizontalMenu.removeAttribute('style');
        document.body.classList.remove('active');

        const subMenus = document.querySelectorAll('.horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu');
        subMenus.forEach((menu) => menu.removeAttribute('style'));

        const activeArrows = document.querySelectorAll('.horizontalMenu-click');
        activeArrows.forEach((arrow) => arrow.classList.remove('horizontal-activearrow'));

        const rotatedArrows = document.querySelectorAll('.horizontalMenu-click02 > i');
        rotatedArrows.forEach((arrow) => arrow.classList.remove('horizontalMenu-rotate'));
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    ...mapActions(['loaderChange', 'changeLang']),
    logout() {
      this.loaderChange(true);
      this.$store.dispatch('logout').then(() => {
        document.location = '/login';
      });
    },
  },
  computed: {
    ...mapState(['logged', 'loader', 'mrl', 'langConfig', 'home', 'csrf']),
    user() {
      return JSON.parse(this.$store.state.user);
    },
  },
  components: {
    Chat,
    Notify,
  },
};
</script>

<style lang="scss">

/* Анимация для плавного открытия/закрытия */
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: all 0.3s ease;
}
.fade-slide-enter, .fade-slide-leave-to /* .fade-slide-leave-active в версии <2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
#notification .profile-dropdown .dropdown-menu {
  display: block !important;
  &:after {
    display: block !important;
  }
  &:before {
    display: block !important;
  }
}
.notification {
  border-radius: 4px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 11px;
  border: 1px solid hsla(0, 0%, 100%, 0.04);
  padding: 10px;
  padding-left: 0;
  background: rgba(32, 34, 60, 0.32);
  height: 35px;
  max-width: 400px;
  transition: opacity 0.3s ease;

  &__content {
    display: flex;
    align-items: center;
    gap: 10px; // расстояние между текстом и кнопкой
  }

  p {
    font-size: 12px;
    color: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin: 0;
    pointer-events: none;
    padding: 0 10px;
    white-space: nowrap; // предотвращает перенос текста
  }

  .notification__button {
    background: rgba(181, 61, 73, 0.5);
    border: 1px solid rgba(219, 78, 92, 0.7);
    color: #ffffff;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    border-radius: 6px;
    cursor: pointer;
    padding: 5px 10px;
    transition: background-color 0.2s ease, transform 0.1s ease;

    &:hover {
      background: rgba(181, 61, 73, 0.6);
      border-color: rgba(219, 78, 92, 0.8);
    }

    &:active {
      transform: scale(0.9);
    }
  }

  // Медиа-запрос для скрытия на маленьких экранах
  @media (max-width: 890px) {
    display: none;
  }
}

body {
 margin:0;
 font-family:Roboto,sans-serif;
 font-size:13px;
 font-weight:400;
 line-height:1.5;
 color:#1a1630;
 text-align:left;
 background:#ebeef1;
 overflow-x:hidden;
overflow-x:hidden;
}

.logoblock .router-link-active {
  background: none;
}

.hor-header .vue-popover {
  left: -138px !important;
  position: absolute !important;
}
.hor-header .vue-popover::before {
  display: none;
}
.show-from-bottom-enter-active,
.show-from-bottom-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}
.show-from-bottom-enter,
.show-from-bottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

		.dark-mode a {
			transition: ease all .2s;
		}
		.dark-mode .horizontalMenu > .horizontalMenu-list > li > a {
			transition: ease all .2s;
      margin: 0 6px;
		}
		.psNotify {
			height: 236px !important;
			 position: relative;
		}
		.notifyText {
			display: flex;
			justify-content: center;
			padding: 10px;
		}

		.flexNotify {
		  display: flex;
		  max-width: 235px;
		  white-space: normal;
		  line-height: normal;
		  font-family: sans-serif;
		  font-size: 12px;
		  font-weight: bold;
		  width: 100%;
			display: flex;
			justify-content: space-between;
		}
		.flexNotify p {
			padding: 0;
			margin: 0;
			font-weight: bold;
			display: flex;
			align-items: center;
		}
		.header-notify.show .dropdown-menu {
			left: -266px !important;
			min-width: 316px;
		}
		#notification a svg:hover {
		  background: #5a5eb9;
		  border-radius: 101px;
		}
		#notification a svg {
		  transition: ease all .2s;
		}
		.horizontalMenu > .horizontalMenu-list > li > a {
			margin: 0 6px;
		}
		.pulse.notPulse {
			right: 5px !important;
			width: 8px !important;
			height: 8px !important;
		}
		.profile-dropdown.show .dropdown-menu[x-placement^="bottom"] {
    left: -156px !important;
}
		
@media only screen 
and (max-device-width : 768px)   { 
		.header-notify.show .dropdown-menu {
    left: 0px !important;
    width: 100% !important;
    position: fixed !important;
    top: 74px !important;
}

		.dark-mode .horizontalMenu > .horizontalMenu-list > li > a {
			transition: ease all .2s;
      margin: 0;
		}

#notify .dropdown-item.border-bottom.d-flex.pl-4 {
    padding-right: 1rem !important;
}
.flexNotify {

    max-width: 85%;

}
.profile-dropdown.show .dropdown-menu[x-placement^="bottom"] {
    left: 0px !important;
}
.dropdown-menu-arrow.dropdown-menu-right::before {
	display: none;
}
.header .profile-dropdown .dropdown-menu {
    top: 74px !important;
    left: 0px !important;
    width: 100%;
    position: fixed !important;
}
.dropdown-menu-arrow.dropdown-menu-right::after {
	display: none;
}

}
	
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.fade-enter-active {
    transition: opacity 2s ease-in-out;
}
.fade-enter {
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 0;
    height: 100%;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
