export default {
  SWITCH_MOBILE: (state) => {
    state.isMobile = true;
    state.isDesktop = false;
  },
  
  SWITCH_DESKTOP: (state) => {
    state.isMobile = false;
    state.isDesktop = true;
  },
  auth_success(state, arr){
    state.token = arr[0];
    state.user = arr[1];
    state.logged = true;
  },
  auth_socket(state, v){
    state.socketAuth = v;
  },
  SET_TITLE(state, v){
    state.home = v;
  },
  SET_MRL(state, v){
    state.mrl = v;
  },
  auth_error(state){
    state.logged = false;
  },
  loaderChange(state, value){
    state.loader = value;
  },
  langActive(state, value){
    state.langConfig.active = value;
  },
  twoAuth(state, value){
    state.twoAuth = value;
  },
  setCsrfToken(state, { token, expire, refreshToken }) {
    state.csrf.token = token;
    state.csrf.tokenExpire = expire;
    state.csrf.refreshToken = refreshToken;
  },
  updateToken(state, arr){
    state.auth.token = arr[0];
    state.auth.tokenExpire = arr[1];
    state.auth.tokenRefresh = arr[2];
  },
  updateTokenCsrf(state, arr){
    state.csrf.token = arr[0];
    state.csrf.tokenExpire = arr[1];
  },
  logout(state){
    state.logged = false;
    state.token = '';
    state.user = '';
  },
}
