  import Vue from 'vue';  

  import store from '../vuex/store'
  import VueSocketIO from 'vue-socket.io'
  import configStresser from './config'

  let getUrl = 'wss://'+location.hostname+'/';
  let mrlInit = document.head.querySelector("[name~=mrl][content]");

  Vue.use(new VueSocketIO({
    debug: false,
    connection: process.env.NODE_ENV === 'development' ? configStresser.devUrl : (mrlInit.content.length > 0 ? getUrl : configStresser.homeUrl),
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
    options: {transports: ['websocket'], path: "/ws", withCredentials: true, autoConnect: false, autoReconnect: false,  reconnection: false} //Optional options
  }));


