
import store from '../vuex/store';

var words = {
        // main
        home: [
            'Home', // en
            'Главная', // ru
        ],
        attackPanel: [
            'Attack Panel', 
            'Панель атаки',
        ],
        purchase: [
            'Purchase', 
            'Покупка', 
        ],
        api: [
            'API Manager', 
            'Менеджер API', 
        ],
        help: [
            'Helpdesk', 
            'Служба поддержки', 
        ],
        faq: [
            'FAQ', 
            'Помощь', 
        ],
        contact: [
            'Contact', 
            'Контакты', 
        ],

        notifications: [
            'Notifications', 
            'Уведомления', 
        ],
        profile: [
            'Notifications', 
            'Уведомления', 
        ],
        exit: [
            'Sign Out', 
            'Выход', 
        ],
};

export function translation(value) {
    return words[value][store.state.langConfig.active];
}

export default { words }