import Vue from 'vue'
import VueRouter from 'vue-router'
import Secure from '../components/Secure.vue'
import store from '../vuex/store'
Vue.use(VueRouter)
let iUser = JSON.parse(store.state.user);
const routes = [
  {
    path: '/home',
    name: 'Home',
    props: true,
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/login.vue')
  }, 
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/register.vue')
  },
  {
    path: '/panel',
    name: 'Panel',
    //component: () => ( iUser.group == 1 || iUser.group == 4 ) ? import('../views/admin/panel.vue') : import('../views/panel/index.vue')
    component: () => import('../views/panel/index.vue')
  },  
  // {
  //   path: '/panel',
  //   name: 'Panel',
  //   component: () => ( iUser.group == 1 || iUser.group == 4 ) ? import('../views/admin/panel.vue') : import('../views/panel/index.vue')
  // },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/faq/index.vue')
  },
  {
    path: '/help',
    name: 'Helpdesk',
    component: () => import('../views/help/index.vue')
  },
  {
    path: '/api',
    name: 'API',
    component: () => import('../views/api/index.vue')
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: () => import('../views/purchase/index.vue')
  },
  {
    path: '/payments/:id',
    name: 'Payments',
    component: () => import('../views/payments/index.vue'),
  }, 
  {
    path: '/profile',
    name: 'Profile', 
    component: () => import('../views/profile/index.vue')
  },
  // otherwise redirect to home
  { path: '*', redirect: '/home' },
  {
    path: '/secure',
    name: 'secure',
    component: Secure, 
    meta: { 
      requiresAuth: true
    }
  },


  {
    path: '/404',
    name: '404', 
    component: () => import('../views/error/404.vue')
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Stresse.IO - ';

// Включение загрузчика перед каждым переходом
router.beforeEach((to, from, next) => {
  // Убираем активное состояние меню
  $("body").removeClass('active');

  // Включаем загрузчик
  store.dispatch('loaderChange', true);

  // Настройка заголовка страницы
  document.title = store.state.home + ' - ' + to.name;

  // Проверка доступа
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return document.location = '/login';
  } else if (loggedIn && !authRequired) {
    return next('/home');
  }

  next();
});

router.afterEach(() => {

});

export default router;
