import axios from "axios"
import configStresser from '../../helpers/config';
import lang from '../../components/lang';
import store from '../../vuex/store';
export default {
  SET_MOBILE({commit}) {
    commit('SWITCH_MOBILE')
  },
  SET_DESKTOP({commit}) {
    commit('SWITCH_DESKTOP')
  },
  SET_AUTH_SOCKET({commit}, value){
    commit('auth_socket', value)
  },
  async fetchCsrfToken({ commit }) {
    try {
      const url = store.state.logged ? '/get_csrf_token': '/get_csrf';
      const response = await axios.get(url);
      commit('setCsrfToken', {
        token: response.data.csrf_token,
        expire: response.data.csrf_token_expire,
        refreshToken: response.data.refresh_token,
      });
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  },
  async refreshCsrfToken({ state, commit }) {
    try {
      const response = await axios.post('/refresh_csrf_token', {
        refresh_token: state.csrf.refreshToken,
      });
      commit('setCsrfToken', {
        token: response.data.csrf_token,
        expire: response.data.csrf_token_expire,
        refreshToken: state.csrf.refreshToken,
      });
    } catch (error) {
      console.error('Error refreshing CSRF token:', error);
    }
  },
  startTokenRefresh({ dispatch, state }) {
    setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const expireTime = state.csrf.tokenExpire;

      if (expireTime && expireTime <= currentTime) {
        dispatch('refreshCsrfToken');
      }
    }, 60000); // Проверка каждые 60 секунд
  },
  loginTwo({commit}, user){
    return new Promise((resolve, reject) => {

      axios({url: '/auth/login', data: user, method: 'POST' })
      .then(resp => {
    
        if (resp.data.status == 'error') {
          commit('auth_error')
          localStorage.removeItem('token')
        } else {
            let userInfo = JSON.stringify(resp.data.user)
            let token = resp.data.user.token;
            localStorage.setItem('token', token);
            localStorage.setItem('user', userInfo);
            axios.defaults.headers.common['Authorization'] = token;

            commit('auth_success', [token, userInfo]);
            resolve(resp);
            window.location('/home');
        }

        this._vm.$toast(resp.data.msg, {
          type: resp.data.status,
          position: configStresser.notifyPosition,
          timeout: 2000
        });
        
        //window.ROOT_VUE.$toast(resp.data.msg, {timeout: 2000, type: resp.data.status})

      })
      .catch(err => {
        commit('auth_error')
        localStorage.removeItem('token')
        reject(err)
      })
    })
  },
  login({commit}, user){
    return new Promise((resolve, reject) => {

      axios({url: '/auth/login', data: user, method: 'POST' })
      .then(resp => {
     
        if (resp.data.status == 'error') {
          commit('auth_error')
          localStorage.removeItem('token')
        } else if (resp.data.status == 'redirect') {
          window.location.assign('https://' + configStresser.defaultDomain);
        } else {
          if (resp.data.twoAuth) {
            commit('twoAuth', true);
          } else {
            let userInfo = JSON.stringify(resp.data.user)
            let token = resp.data.user.token;
            localStorage.setItem('token', token);
            localStorage.setItem('user', userInfo);
            axios.defaults.headers.common['Authorization'] = token;
  
            commit('auth_success', [token, userInfo]);
            resolve(resp);
            window.location('/home');
          }
        }
        if (resp.data.msg) {
          this._vm.$toast(resp.data.msg, {
            type: resp.data.status,
            position: configStresser.notifyPosition,
            timeout: 2000
          });
        }

        
        //window.ROOT_VUE.$toast(resp.data.msg, {timeout: 2000, type: resp.data.status})

      })
      .catch(err => {
        commit('auth_error')
        localStorage.removeItem('token')
        reject(err)
      })
    })
},
register({commit}, user){
  return new Promise((resolve, reject) => {
    axios({url: '/auth/register', data: user, method: 'POST' })
    .then(resp => {
      const token = resp.data.token
      const user = resp.data.user
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] = token
      commit('auth_success', token, user)
      resolve(resp)
    })
    .catch(err => {
      commit('auth_error', err)
      localStorage.removeItem('token')
      reject(err)
    })
  })
},
loaderChange({commit}, value){
    commit('loaderChange', value)
},
updateToken({commit}, value){
  commit('updateToken', value)
},
changeLang({commit}, value){
  localStorage.setItem('lang', value);
  location.reload();
},
updateTokenCsrf({commit}, value){
  commit('updateTokenCsrf', value)
},
async logoutNow({commit}){
    commit('logout')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
},
async logout({commit}){
  return new Promise((resolve, reject) => {
    axios({url: '/auth/logout', method: 'POST' })
    .then(resp => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      //document.location.href="/login";
      
      resolve()
    })
    .catch(err => {
      commit('auth_error', err)
      localStorage.removeItem('token')
      reject(err)
    })
  })
}
}
